<template>
  <BaseScreen
    name="end"
    :class="['endscreen', 'is-level-' + (level.index + 1)]"
  >
    <div class="endscreen__header">
      <StarGraphic :number="level.index + 1" class="endscreen__star" />

      <h2 role="alert">{{ $l10n('finish-rank', $l10n(level.index + 1)) }}</h2>
      <p v-if="level.message">{{ level.message }}</p>

      <p v-if="showUUID && session">
        {{ $l10n('your-uuid') }}
        <code class="endscreen__code">{{ session }}</code>
      </p>

      <BaseButton
        name="share"
        location="end"
        is-primary
        @click="showModal('share')"
        >{{ $l10n('share-level') }}</BaseButton
      >

      <BaseButton
        name="restart"
        location="end"
        is-plain
        @click="$emit('restart')"
        >{{ $l10n('restart') }}</BaseButton
      >
    </div>

    <div
      class="endscreen__graphic"
      :style="{
        'background-image': `url(${$url(level.graphic)})`,
      }"
    />
  </BaseScreen>
</template>

<script>
export default {
  inject: ['showUUID'],
  props: {
    level: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    session() {
      const session = this.$store.state.session;

      if (this.showUUID === true) {
        return session;
      } else if (this.showUUID) {
        return session.substr(-1 * this.showUUID);
      }

      return false;
    },
  },
};
</script>
