<template>
  <BaseScreen :name="id" class="introsurvey">
    <div v-content="text" class="introsurvey__header" />

    <div class="introsurvey__body">
      <Component :is="uiComponent" v-bind="control" @answer="logAnswer" />
    </div>
  </BaseScreen>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    control: {
      type: Object,
      required: true,
    },
  },
  data() {
    let uiComponent = 'ButtonsQuestion';
    if (this.control.type === 'slider') {
      uiComponent = 'SliderQuestion';
    }

    return {
      uiComponent,
    };
  },
  computed: {
    ...mapState(['currentScreen']),
    ...mapGetters(['getAnswer']),

    isActive() {
      return this.currentScreen === this.id;
    },

    hasAnswer() {
      return this.answer !== null;
    },
  },
  methods: {
    ...mapMutations(['saveAnswer']),

    logAnswer(answer) {
      this.saveAnswer({
        question: this.id,
        answer,
      });

      this.$log.demographic({
        question_name: this.name,
        answers: [answer],
      });

      this.$emit('answer', answer);
    },
  },
};
</script>
