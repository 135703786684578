function fixText(string) {
  // Ensure hyphens don't break
  string = string.replace(/(\w)-(\w)/g, '$1&#8209;$2');

  // Join last 2 words with NBSP if short enough
  string = string.replace(/(\S{1,10}) (\S{1,10})$/, '$1&nbsp;$2');

  return string;
}

fixText.install = function (Vue) {
  Vue.prototype.$text = fixText;

  Vue.directive('content', {
    inserted(el, binding) {
      el.innerHTML = fixText(binding.value || '');
    },
    update(el, binding) {
      if (binding.value !== binding.oldValue) {
        el.innerHTML = fixText(binding.value || '');
      }
    },
  });
};

export default fixText;
