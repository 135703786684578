import circlesIntersect from 'circles-intersect';

export default function checkIntersect(elm1, elm2, margin = 0) {
  const area1 = elm1.getBoundingClientRect();
  const area2 = elm2.getBoundingClientRect();

  const r1 = area1.width / 2;
  const x1 = area1.left + r1;
  const y1 = area1.top + r1;

  const r2 = area2.width / 2;
  const x2 = area2.left + r2;
  const y2 = area2.top + r2;

  return circlesIntersect(x1, y1, r1 + r1 * margin, x2, y2, r2);
}
