import styled from 'vue-styled-components';
import backdropLeft from '../../assets/img/backdrop-left.svg';
import backdropRight from '../../assets/img/backdrop-right.svg';
import get from 'better-theme-get';

const bgElements = `
  ${get('mediaQueries', 'desktopWidth')} {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 30%;
      max-width: calc(50% - 200px);
      background: no-repeat;
      background-size: cover;
    }

    &:before {
      top: -30%;
      height: 140%;
      left: 0;
      background-image: url(${backdropLeft});
      background-position: right center;
    }
    &:after {
      top: -7%;
      height: 110%;
      right: 0;
      background-image: url(${backdropRight});
      background-position: left center;
    }
  }
`;

export default styled.div`
  &__toolbar {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 15px;

    &.above {
      z-index: 21; // above modals
      top: 0;
      left: 0;
    }

    &.below {
      z-index: 5; // above screens
      bottom: 0;
      right: 0;
    }

    .button:not(:first-child) {
      margin: 0 0 0 0.5em;
    }
  }
  ${({ theme }) => (theme.prefs.showBackgroundElements ? bgElements : '')}
`;
