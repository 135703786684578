<template>
  <div>
    <BaseScreen v-if="show_preface" name="introsurvey" class="introsurvey">
      <div class="introsurvey__inner">
        <div v-html="preface" />

        <div class="introsurvey__buttons">
          <BaseButton is-secondary @click="startSurvey">{{
            $l10n('continue')
          }}</BaseButton>
        </div>
      </div>
    </BaseScreen>

    <IntroQuestionScreen
      v-for="question in questions"
      :key="question.id"
      v-bind="question"
      @answer="nextQuestion"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    show_preface: Boolean,
    preface: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentQuestion: 0,
    };
  },
  computed: {
    ...mapGetters(['getAnswer']),
  },
  methods: {
    ...mapMutations(['goToScreen']),

    start() {
      if (this.show_preface) {
        this.goToScreen('introsurvey');
      } else {
        this.startSurvey();
      }
    },

    nextQuestion() {
      this.currentQuestion++;
      while (this.currentQuestion < this.questions.length) {
        const question = this.questions[this.currentQuestion];
        if (question.prerequisite_question) {
          const answer = this.getAnswer(question.prerequisite_question);
          if (answer === question.prerequisite_answer) {
            break;
          }
        } else {
          break;
        }
        this.currentQuestion++;
      }

      if (this.currentQuestion >= this.questions.length) {
        this.$emit('done');
        return;
      }

      this.goToScreen(this.questions[this.currentQuestion].id);
    },

    startSurvey() {
      this.goToScreen(this.questions[0].id);
    },
  },
};
</script>
